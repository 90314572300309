import {
  Avatar,
  Button,
  ButtonVariant,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownList,
  Masthead,
  MastheadBrand,
  MastheadContent,
  MastheadToggle,
  MenuToggle,
  PageContextConsumer,
  PageContextProps,
  PageToggleButton,
  PageToggleButtonProps,
  Toolbar,
  ToolbarContent,
  ToolbarItem,
} from "@patternfly/react-core";
import { BarsIcon, EllipsisVIcon, HelpIcon } from "@patternfly/react-icons";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHref } from "react-router-dom";
import { useEnvironment, useHelp } from "@keycloak/keycloak-ui-shared";
import { HelpHeader } from "./components/help-enabler/HelpHeader";
import { useRealm } from "./context/realm-context/RealmContext";
import { useWhoAmI } from "./context/whoami/WhoAmI";
import { toDashboard } from "./dashboard/routes/Dashboard";
import { environment } from "./environment";
import "./pageHeader.css";

const ManageAccountDropdownItem = () => {
  const { keycloak } = useEnvironment();
  const { t } = useTranslation();

  return (
    <DropdownItem
      key="manage account"
      id="manage-account"
      onClick={() => keycloak.accountManagement()}
    >
      {t("manageAccount")}
    </DropdownItem>
  );
};

const SignOutDropdownItem = () => {
  const { keycloak } = useEnvironment();
  const { t } = useTranslation();

  return (
    <DropdownItem
      id="sign-out"
      key="sign out"
      onClick={() => keycloak.logout({ redirectUri: "" })}
    >
      {t("signOut")}
    </DropdownItem>
  );
};

const ServerInfoDropdownItem = () => {
  const { realm } = useRealm();
  const { t } = useTranslation();

  return (
    <DropdownItem
      key="server info"
      component={(props) => <Link {...props} to={toDashboard({ realm })} />}
    >
      {t("realmInfo")}
    </DropdownItem>
  );
};

const HelpDropdownItem = () => {
  const { t } = useTranslation();
  const { enabled, toggleHelp } = useHelp();

  return (
    <DropdownItem
      data-testId="helpIcon"
      icon={<HelpIcon />}
      onClick={toggleHelp}
    >
      {enabled ? t("helpEnabled") : t("helpDisabled")}
    </DropdownItem>
  );
};

const kebabDropdownItems = [
  <ManageAccountDropdownItem key="kebab Manage Account" />,
  <ServerInfoDropdownItem key="kebab Server Info" />,
  <HelpDropdownItem key="kebab Help" />,
  <Divider component="li" key="kebab sign out separator" />,
  <SignOutDropdownItem key="kebab Sign out" />,
];

const userDropdownItems = [
  <ManageAccountDropdownItem key="Manage Account" />,
  <ServerInfoDropdownItem key="Server info" />,
  <Divider component="li" key="sign out separator" />,
  <SignOutDropdownItem key="Sign out" />,
];

const KebabDropdown = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Dropdown
      className="userDropdownKebab"
      isPlain
      onOpenChange={(isOpen) => setDropdownOpen(isOpen)}
      toggle={(ref) => (
        <MenuToggle
          id="user-dropdown-kebab"
          ref={ref}
          variant="plain"
          onClick={() => setDropdownOpen(!isDropdownOpen)}
          isExpanded={isDropdownOpen}
        >
          <EllipsisVIcon />
        </MenuToggle>
      )}
      isOpen={isDropdownOpen}
    >
      <DropdownList>{kebabDropdownItems}</DropdownList>
    </Dropdown>
  );
};

const UserDropdown = () => {
  const { whoAmI } = useWhoAmI();
  const { keycloak } = useEnvironment();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [lastLoginTime, setLastLoginTime] = useState("0");
  const picture = keycloak.tokenParsed?.picture;

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
          `/admin/realms/${keycloak.realm}/users/${keycloak.subject}`,
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
            },
          },
        );
        const data = await response.json();

        if (data.attributes?.lastLoginTime) {
          const epochTime = data.attributes.lastLoginTime[0];
          const localTime = new Date(parseInt(epochTime)).toLocaleString(); // Convert to local time
          setLastLoginTime(localTime);
        }
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };

    fetchUserInfo();
  }, [keycloak]);

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className="userDropdownList"
    >
      {/* Last login time displayed to the left */}
      <p style={{ marginRight: "1rem" }}>Last login at: {lastLoginTime}</p>

      <Dropdown
        isPlain
        isOpen={isDropdownOpen}
        onOpenChange={(isOpen) => setDropdownOpen(isOpen)}
        toggle={(ref) => (
          <MenuToggle
            id="user-dropdown"
            ref={ref}
            onClick={() => setDropdownOpen(!isDropdownOpen)}
          >
            {/* {whoAmI.getDisplayName()} */}
            <div className="user-dropdown-toggle">
              <Avatar
                src={picture || environment.resourceUrl + "/img_avatar.svg"}
                alt={whoAmI.getDisplayName()}
                aria-label={whoAmI.getDisplayName()}
                size="md"
                className="user-avatar"
              />
              <span className="username-dropdown">
                {whoAmI.getDisplayName()}
              </span>
            </div>
          </MenuToggle>
        )}
        // className="user-dropdown-list"
      >
        <DropdownList>{userDropdownItems}</DropdownList>
      </Dropdown>
    </div>
  );
};

export const Header = () => {
  const { environment, keycloak } = useEnvironment();
  const { t } = useTranslation();
  const { realm } = useRealm();

  // const picture = keycloak.tokenParsed?.picture;
  const logo = environment.logo ? environment.logo : "/ZTrustLogo.svg";
  const url = useHref(toDashboard({ realm }));
  const logoUrl = environment.logoUrl ? environment.logoUrl : url;
  const [showSidebarToggle, setShowSidebarToggle] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setShowSidebarToggle(true);
      } else {
        setShowSidebarToggle(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Masthead>
      <MastheadToggle>
        {showSidebarToggle && (
          <PageToggleButton variant="plain" aria-label={t("navigation")}>
            <BarsIcon />
          </PageToggleButton>
        )}
      </MastheadToggle>
      <MastheadBrand href={logoUrl}>
        <img
          src={environment.resourceUrl + logo}
          id="masthead-logo"
          alt={t("logo")}
          aria-label={t("logo")}
          className="keycloak__pageheader_brand"
        />
      </MastheadBrand>
      <MastheadContent>
        <Toolbar>
          <ToolbarContent>
            <ToolbarItem
              align={{ default: "alignRight" }}
              visibility={{
                default: "hidden",
                md: "visible",
              }} /** the settings and help icon buttons are only visible on desktop sizes and replaced by a kebab dropdown for other sizes */
            >
              <HelpHeader />
            </ToolbarItem>

            <ToolbarItem
              align={{ default: "alignLeft" }}
              visibility={{
                md: "hidden",
              }}
            >
              <KebabDropdown />
            </ToolbarItem>

            <ToolbarItem
              visibility={{
                default: "hidden",
                md: "visible",
              }}
            >
              <UserDropdown />
            </ToolbarItem>
          </ToolbarContent>
        </Toolbar>
      </MastheadContent>
    </Masthead>
  );
};
