import {
  Nav,
  NavGroup,
  NavItem,
  NavList,
  PageSidebar,
  PageSidebarBody,
  Button,
} from "@patternfly/react-core";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useMatch, useNavigate } from "react-router-dom";
import { RealmSelector } from "./components/realm-selector/RealmSelector";
import { useAccess } from "./context/access/Access";
import { useRealm } from "./context/realm-context/RealmContext";
import { useServerInfo } from "./context/server-info/ServerInfoProvider";
import { toPage } from "./page/routes";
import { AddRealmRoute } from "./realm/routes/AddRealm";
import { routes } from "./routes";
import useIsFeatureEnabled, { Feature } from "./utils/useIsFeatureEnabled";

import {
  ApplicationsIcon,
  CubesIcon,
  UserCogIcon,
  UserIcon,
  UsersIcon,
  UserClockIcon,
  CalendarIcon,
  CogIcon,
  LockIcon,
  ExchangeAltIcon,
  DatabaseIcon,
  HeadsetIcon,
  BarsIcon,
  InfrastructureIcon,
  PrintIcon,
} from "@patternfly/react-icons";

import "./page-nav.css";

type LeftNavProps = {
  title: string;
  path: string;
  id?: string;
  icon?: React.ReactNode;
  showTitle: boolean;
};

const LeftNav = ({ title, path, id, icon, showTitle }: LeftNavProps) => {
  const { t } = useTranslation();
  const { hasAccess } = useAccess();
  const { realm } = useRealm();
  const encodedRealm = encodeURIComponent(realm);
  const isExternalLink = path === "https://www.ztrust.in/contact";

  const route = routes.find(
    (route) =>
      route.path.replace(/\/:.+?(\?|(?:(?!\/).)*|$)/g, "") === (id || path),
  );

  const accessAllowed =
    route &&
    (route.handle.access instanceof Array
      ? hasAccess(...route.handle.access)
      : hasAccess(route.handle.access));

  if (!accessAllowed && !isExternalLink) return null;

  return (
    <li>
      {isExternalLink ? (
        <a
          id="nav-item-helpdesk"
          href="#"
          className="pf-v5-c-nav__link"
          onClick={(e) => {
            e.preventDefault();
            window.open(path, "_blank");
          }}
        >
          {icon && <span className="icon">{icon}</span>}
          {t(showTitle ? title : "")}
        </a>
      ) : (
        <NavLink
          id={"nav-item" + path.replace("/", "-")}
          to={`/${encodedRealm}${path}`}
          className={({ isActive }) =>
            `pf-v5-c-nav__link${isActive ? " pf-m-current" : ""}`
          }
          // onClick={() => (isExternalLink ? window.open(path, "_blank") : null)}
        >
          {icon && <span className="icon">{icon}</span>}
          {t(showTitle ? title : "")}
        </NavLink>
      )}
    </li>
  );
};

export const PageNav = () => {
  const { t } = useTranslation();
  const { hasSomeAccess } = useAccess();
  const { componentTypes } = useServerInfo();
  const isFeatureEnabled = useIsFeatureEnabled();
  const pages =
    componentTypes?.["org.keycloak.services.ui.extend.UiPageProvider"];
  const navigate = useNavigate();
  const { realmRepresentation } = useRealm();
  const [showItems, setShowItems] = useState(true);
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1400) {
        setShowItems(true);
      } else {
        setShowItems(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  type SelectedItem = {
    groupId: number | string;
    itemId: number | string;
    to: string;
    event: FormEvent<HTMLInputElement>;
  };

  const onSelect = (item: SelectedItem) => {
    navigate(item.to);
    item.event.preventDefault();
  };

  const showManage = hasSomeAccess(
    "view-realm",
    "query-groups",
    "query-users",
    "query-clients",
    "view-events",
  );

  const showConfigure = hasSomeAccess(
    "view-realm",
    "query-clients",
    "view-identity-providers",
  );

  const isOnAddRealm = !!useMatch(AddRealmRoute.path);

  return (
    <PageSidebar
      className={`keycloak__page_nav__nav ${showSidebar ? "expanded" : "collapsed"}`}
      isSidebarOpen={showSidebar}
    >
      <PageSidebarBody>
        <Nav
          onSelect={(_event: any, item: any) => onSelect(item as SelectedItem)}
        >
          <NavList className="nav-list">
            <div
              className={`nav-item-container ${showSidebar ? "visible" : "hidden"}`}
            >
              <NavItem className="keycloak__page_nav__nav_item__realm-selector">
                <RealmSelector />
              </NavItem>
            </div>
            <div className="button-container">
              <Button
                variant="link"
                onClick={() => setShowSidebar(!showSidebar)}
                aria-label={`sidebar-container`}
                className="sidebar-toggle"
              >
                <BarsIcon />
              </Button>
            </div>
          </NavList>
          {showManage && !isOnAddRealm && (
            <NavGroup
              aria-label={t("manage")}
              title={t(showSidebar ? "manage" : "")}
            >
              {isFeatureEnabled(Feature.Organizations) &&
                realmRepresentation?.organizationsEnabled && (
                  <LeftNav
                    title="organizations"
                    path="/organizations"
                    icon={<InfrastructureIcon />}
                    showTitle={showSidebar}
                  />
                )}
              <LeftNav
                title="clients"
                path="/clients"
                icon={<ApplicationsIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="clientScopes"
                path="/client-scopes"
                icon={<CubesIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="realmRoles"
                path="/roles"
                icon={<UserCogIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="users"
                path="/users"
                icon={<UserIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="groups"
                path="/groups"
                icon={<UsersIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="sessions"
                path="/sessions"
                icon={<UserClockIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="events"
                path="/events"
                icon={<CalendarIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="Reports"
                path="/user-reports"
                icon={<PrintIcon />}
                showTitle={showSidebar}
              />
            </NavGroup>
          )}

          {showConfigure && !isOnAddRealm && (
            <NavGroup
              aria-label={t("configure")}
              title={t(showSidebar ? "configure" : "")}
            >
              <LeftNav
                title="realmSettings"
                path="/realm-settings"
                icon={<CogIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="authentication"
                path="/authentication"
                icon={<LockIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="identityProviders"
                path="/identity-providers"
                icon={<ExchangeAltIcon />}
                showTitle={showSidebar}
              />
              <LeftNav
                title="userFederation"
                path="/user-federation"
                icon={<DatabaseIcon />}
                showTitle={showSidebar}
              />
              {isFeatureEnabled(Feature.DeclarativeUI) &&
                pages?.map((p) => (
                  <LeftNav
                    key={p.id}
                    title={p.id}
                    path={toPage({ providerId: p.id }).pathname!}
                    id="/page-section"
                    showTitle={showSidebar}
                  />
                ))}
            </NavGroup>
          )}

          {(showManage || showConfigure) && !isOnAddRealm && (
            <NavGroup
              aria-label={t("support")}
              title={t(showSidebar ? "support" : "")}
            >
              <LeftNav
                title="helpdesk"
                path="https://www.ztrust.in/contact"
                icon={<HeadsetIcon />}
                showTitle={showSidebar}
              />
            </NavGroup>
          )}
        </Nav>
      </PageSidebarBody>
    </PageSidebar>
  );
};
